import { InformationControl } from "../../../controls/InformationControl/InformationControl";


export function NoTypesFound() {
  return (
    <InformationControl
      id={"no-tag-types-found"}
      title="Nothing has been found"
      description="There are no tags that meet the specified search query"
    />
  );
}
